"use client";
import HeaderSearchSection from "@/app/_components/layout/Header/HeaderSearchSection";
import NotificationMenu from "@/app/_components/layout/Header/notification/NotificationMenu";
import NavBar from "@/app/_components/layout/NavBar";
import { getHomepagePath } from "@/app/_services/auth.action";
import {
  INTRODUCTION_PAGE_PATH,
  NORMAL_SEARCH,
  SIGNIN_PAGE,
  SIGNUP_PAGE,
} from "@/services/constants/routerPath";
import useMultiLanguageConstant from "@/services/hooks/useMultiLanguageConstant";
import { useUserInfo } from "@/services/hooks/useUserInfo";
import { ChevronLeftIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Button,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Image,
  Show,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";

import { useTranslation } from "react-i18next";

const UserInfoSection = dynamic(
  () => import("@/app/_components/layout/Header/HeaderUserInfoSection"),
  { ssr: false }
);

const hideSearchSectionPaths = ["/", INTRODUCTION_PAGE_PATH];

export default function Header({
  showSearchSection = true,
  showRightSection = true,
  isAuthRoutes = false,
}) {
  const { t } = useTranslation();
  const pathname = usePathname();
  const { push, back } = useRouter();
  const { isOpen, onToggle } = useDisclosure();
  const { NavigationMenu } = useMultiLanguageConstant();

  const hideSearchSection = hideSearchSectionPaths.includes(pathname);

  const isIntroPage = pathname === "/";

  const userData = useUserInfo();

  const getRouteTitle = () => {
    if (isOpen) {
      return t("Menu");
    }
    const currentMenu = NavigationMenu.find((item) =>
      pathname.includes(item.url)
    );
    return currentMenu?.label;
  };

  return (
    <>
      <Show above="lg">
        <Stack className="custom-header-container">
          <Grid
            as="header"
            h={20}
            templateColumns="70% 30%"
            w="container.xl"
            marginLeft="auto"
            marginRight="auto"
          >
            <GridItem as={HStack}>
              <Link href={getHomepagePath(userData?.type)}>
                <Image
                  src="/beauti_bucks_logo.jpeg"
                  h="48px"
                  alt="Beauti buck logo"
                />
              </Link>

              {showSearchSection && !hideSearchSection && (
                <HeaderSearchSection />
              )}
            </GridItem>
            {/* <GridItem as={HStack} justify="center">
            {showSearchSection && !hideSearchSection && <HeaderSearchSection />}
          </GridItem> */}
            <GridItem as={HStack} justify="flex-end">
              {showRightSection && <UserInfoSection />}
            </GridItem>
          </Grid>
        </Stack>
      </Show>

      <Show below="lg">
        {isAuthRoutes ? (
          <HStack as="header" h={14} gap={0} justify="center" pos="relative">
            <IconButton
              icon={<ChevronLeftIcon boxSize={6} />}
              variant="unstyled"
              onClick={() => back()}
              left="-10px"
              pos="absolute"
              top={2}
            />
            <Text as="strong" fontSize={16}>
              {getRouteTitle()}
            </Text>
          </HStack>
        ) : (
          <HStack as="header" h={14} gap={0} justify="space-between">
            <Link href={getHomepagePath(userData?.type)}>
              <Image
                src="/beauti_bucks_logo.jpeg"
                alt="Beauti buck logo"
                maxW="100px"
              />
            </Link>
            {isIntroPage ? (
              <HStack>
                <Button
                  h={8}
                  variant="outline"
                  onClick={() => push(SIGNIN_PAGE)}
                >
                  {t("Sign In")}
                </Button>
                <Button h={8} onClick={() => push(SIGNUP_PAGE)}>
                  {t("Sign Up")}
                </Button>
              </HStack>
            ) : (
              <>
                <Heading
                  flexGrow={1}
                  textAlign="center"
                  fontSize={{ base: 16, md: 20 }}
                >
                  {getRouteTitle()}
                </Heading>
                <IconButton
                  icon={<Search2Icon boxSize={4} />}
                  variant="unstyled"
                  onClick={() => push(NORMAL_SEARCH)}
                />
                <NotificationMenu />
                <NavBar isOpen={isOpen} onToggle={onToggle} />
              </>
            )}
          </HStack>
        )}
      </Show>
    </>
  );
}
