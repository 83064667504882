"use client";
import { UserType } from "@/services/constants/global";
import { HOMEPAGE_PATH } from "@/services/constants/routerPath";

export const getHomepagePath = (userType) => {
  switch (userType) {
    case UserType.CORPORATE:
      return HOMEPAGE_PATH;
    default:
      return HOMEPAGE_PATH;
  }
};

export const logout = () => {
  localStorage.clear();
  window.localStorage.clear();
  sessionStorage.clear();
  window.location.assign(HOMEPAGE_PATH);
};
